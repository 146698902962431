"use client";

import * as Sentry from "@sentry/nextjs";
import Layout from "components/Layout";
import { t } from "locales";
import Error from "next/error";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
export default function ErrorPage({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <>
            <title>{`${t.layouts.error} - ${t["site-name"]}`}</title>
            <meta name="robots" content="noindex" data-sentry-element="meta" data-sentry-source-file="error.tsx" />
            <Layout isSimple={true} data-sentry-element="Layout" data-sentry-source-file="error.tsx">
                <StyledGridContainer container alignItems="center" direction="column" justifyContent="center" spacing={4} data-sentry-element="StyledGridContainer" data-sentry-source-file="error.tsx">
                    <Grid item data-sentry-element="Grid" data-sentry-source-file="error.tsx">
                        <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="error.tsx">{t.layouts.error}</Typography>
                    </Grid>
                    <Grid item data-sentry-element="Grid" data-sentry-source-file="error.tsx">
                        <Link href="/" underline="always" variant="h4" data-sentry-element="Link" data-sentry-source-file="error.tsx">
                            {t["go-to-homepage"]}
                        </Link>
                    </Grid>
                </StyledGridContainer>
            </Layout>
        </>;
}
const StyledGridContainer = styled(Grid)(() => ({
  margin: "0 auto",
  maxWidth: "80vw",
  minHeight: "60vh"
}));